<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col
              cols="12"
              class="pl-0 pr-0 pb-1 pt-12"
            >
              <v-row>
                <v-spacer />

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-select
                    v-model="santral"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="startDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="3"
                  sm="4"
                  md="4"
                  class="pr-3"
                >
                  <v-slider
                    v-model="hoursModel"
                    :thumb-size="50"
                    thumb-label="always"
                  >
                    {{ hoursModel }}
                    <template v-slot:thumb-label="{}">
                      {{ selectHour }}
                    </template>
                  </v-slider>
                </v-col>

                <v-col
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3"
                >
                  <v-btn
                    color="primary"
                    @click="getDailyMinuteEnergy"
                  >
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
            >
              <div
                id="invert_Analysis"
                style="
                  height: 350px;
                  min-width: 900px;
                  width: windowsWidth;
                  padding: 1px;
                "
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import * as Timeofinverter from '@/api/Inverter/Timeofinverter';
import moment from 'moment';
import i18n from '@/locale';
import helper from '@/api/helper';
import { getTimeofinverter } from '@/api/Request/inverter';

export default {
  name: 'Dashboard',
  components: {},
  data: () => ({
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),

    menu: false,
    modal: false,
    menu2: false,
    livehourdata: {},
    inverterList: {},
    measureList: [],
    hoursModel: 50,
    hourSelected: [],
    componentKey: 1,
    selectHour: '',
    inverterPowerData: {},
    santral: '',
    santralInfo: [],
    windowsWidth: `${document.documentElement.clientWidth - 100}px`,
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },
  },
  watch: {
    hoursModel() {
      this.getInverterTimeAcPowers();
    },
  },
  created() {
    this.setIntervalInverter();
  },
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      if (k > 30) {
        clearInterval(myVar1);
      }
      k += 1;
      if (santral !== undefined) {
        self.santralModel = santral;
        self.getDailyMinuteEnergy();
        clearInterval(myVar1);
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    setIntervalInverter() {
      const { selectedFarm } = this.$store.getters;

      if (selectedFarm !== undefined && Object.keys(selectedFarm).length > 0) {
        const stepTime = helper.getFarmStep(selectedFarm.prefix);
        const timeList = [];

        for (let h = 5; h < 22; h += 1) {
          for (let mn = 0; mn < 60; mn += stepTime) {
            let hour;
            let mnt;

            // eslint-disable-next-line no-unused-expressions
            h < 10 ? (hour = `0${h}`) : (hour = `${h}`);
            // eslint-disable-next-line no-unused-expressions
            mn < 10 ? (mnt = `0${mn}`) : (mnt = `${mn}`);
            if (timeList.length < 201) {
              timeList.push(`${hour}:${mnt}`);
            }
          }
        }
        this.hoursModel = 50;
        this.hourSelected = timeList;
        this.selectHour = timeList[timeList.length / 2];
      }
    },
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          column: [
            'ACPower',
            'inverter',
            'INVERTERNAME',
            'INVERTER',
            'santral',
            'date',
            'datetimestamp',
            'PerformanceRatio',
          ],
        },
      };
      getTimeofinverter(params).then((res) => {
        if (helper.isObjectKeysExist(res.data, 'data')) {
          const tempData = Timeofinverter.default.getInverterTime(
            res.data.data,
          );
          tempData.then((data) => {
            this.componentKey += 1;
            this.inverterPowerData = data;
            this.getInverterTimeAcPowers();
          });
        }
      });
    },

    getInverterTimeAcPowers() {
      const inverterDcPower = {};
      const { inverterData } = this.$store.getters;

      Object.keys(inverterData).forEach((item) => {
        if (helper.isObjectKeysExist(inverterData[item], 'INVERTERNAME')) {
          const val = inverterData[item].installedDcPower;
          const key = inverterData[item].INVERTERNAME;
          inverterDcPower[key] = val;
        } else {
          const val = inverterData[item].installedDcPower;
          const key = inverterData[item].INVERTER;
          inverterDcPower[key] = val;
        }
      });

      const res = this.inverterPowerData;
      this.measureList = [
        { value: 'ACPower', text: i18n.t('ACPower'), unit: 'kWh' },
        { value: 'INVERTERNAME', text: 'Inverter DC Güçü', unit: '%' },
      ];

      this.selectHour = this.hourSelected[this.hoursModel];
      const optionData = Timeofinverter.default.getInverterTimeAcPowers(
        res[this.selectHour],
        this.measureList,
        inverterDcPower,
      );
      const chartDom = document.getElementById('invert_Analysis');
      const myChart = echarts.init(chartDom);
      optionData.then((result) => {
        // eslint-disable-next-line no-unused-expressions
        myChart.setOption(result, true);
        // this.livehourdata = result;
        this.componentKey += 1;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
